import React from "react";
import Header from "./Header.jsx"
import Footer from "./Footer.jsx"
import Content from "./Content.jsx"
import {Link, useLocation} from "react-router-dom";



function Gender(){

    const loc = useLocation();
    const pat = loc.pathname + "/male"
    const pat1 = loc.pathname + "/female"

    return(
        <div>
        <Header />
        <Content
        text1 = "friend that's a...."
        perm1 = "1"
        perm2 = "1"
        btn1 = {[<Link class = "nodec" to = {pat}>🕺</Link>]}
        btn2 = {[<Link class = "nodec" to = {pat1}>💃</Link>]}
        />
        <Footer />
        </div>
    );

}

export default Gender;
