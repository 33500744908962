import React from "react";



function Content(props){


    return(
        <div>
        <div class = "nbtns" style={{textAlign: "center", marginTop: '200px', marginBottom: '400px'}}>
        <h1 id = "mainhead" style={{

          fontFamily:"Sacramento",
          fontSize: '70px',

        }}> {props.text1} </h1>

        <div style={{display: "inline-block", marginTop: "40px"}}>

        {props.perm1==1?<div style={{
          border: '0.2px solid',
          width: '390px',
          height: '80px',
          textAlign: 'center',
          fontSize: '35px',
          padding: '10px 40px',
          float: 'left'
        }}>{props.btn1}</div>:""}


        {props.perm1==1?<div style={{width: '150px', float: 'right'}}></div>:""}

        {props.perm2==1?<div style={{
          border: '0.2px solid',
          width: '390px',
          height: '80px',
          textAlign: 'center',
          fontSize: '35px',
          padding: '10px 40px',
          float: 'right'
        }}>{props.btn2}</div>:<div style={{margin: "auto"}}>
        <form id="contactForm" action = "https://formspree.io/f/xbjqpqnl" method = "POST" >
        <textarea name = "suggpara" cols = "55" rows = "5" placeholder = "Do you have a gift in mind?" ></textarea>
        <div style={{
          border: '0.2px solid',
          width: '240px',
          height: '50px',
          textAlign: 'center',
          fontSize: '20px',
          padding: '10px 40px',
          fontFamily: "Roboto",
          position: "relative",
          left: "90px",
          top: "40px"
        }}><button type = "submit" class= "nodec" >Submit</button></div></form>
        </div>}


          {props.note1==1?<div style={{fontFamily: 'Roboto'}}>Click again to receive another gift!</div>:""}


        </div>



        </div>



                <div class = "mobbtns" style={{textAlign: "center", marginTop: '200px', marginBottom: '400px'}}>
                <h1 id = "mainhead" style={{

                  fontFamily:"Sacramento",
                  fontSize: '60px',
                  textAlign: "center"

                }}> {props.text1} </h1>

                <div style={{display: "inline-block", marginTop: "40px"}}>

                {props.perm1==1?<div style={{
                  border: '0.2px solid',
                  width: '300px',
                  height: '80px',
                  textAlign: 'center',
                  fontSize: '35px',
                  padding: '10px 40px',
                  float: 'left'
                }}>{props.btn1}</div>:""}


                {props.perm1==1?<div style={{height: '120px'}}></div>:""}

                {props.perm2==1?<div style={{
                  border: '0.2px solid',
                  width: '300px',
                  height: '80px',
                  textAlign: 'center',
                  fontSize: '35px',
                  padding: '10px 40px',
                  float: 'right'
                }}>{props.btn2}</div>:<div style={{margin: "auto"}}>
                <form id="contactForm" action = "https://formspree.io/f/xbjqpqnl" method = "POST" >
                <textarea name = "suggpara" cols = "40" rows = "5" placeholder = "Do you have a gift in mind?" ></textarea>
                <div align="center" style={{paddingTop: "20px"}}>
                <div style={{
                  border: '0.2px solid',
                  width: '180px',
                  height: '50px',
                  textAlign: 'center',
                  fontSize: '20px',
                  padding: '10px 40px',
                  fontFamily: "Roboto"
                }}><button type = "submit" class= "nodec" >Submit</button></div></div></form>
                </div>}

                {props.note1==1?<div style={{fontFamily: 'Roboto'}}>Click again to receive another gift!</div>:""}

                </div>



                </div>




        </div>
    );

}

export default Content;
