import React from "react";
import { HashRouter as Router, Switch, Route} from "react-router-dom";
import Home from "./Home.js";
import Gender from "./Gender.js";
import Gift from "./Gift.js";
import Suggest from "./Suggest.js";

function App(){

    return(
      <Router>
              <Switch>
                      <Route path="/" exact component={Home} />
                      <Route path="/funny" exact component={Gender} />
                      <Route path="/nice" exact component={Gender} />
                      <Route path="/funny/male" exact component={Gift} />
                      <Route path="/nice/male" exact component={Gift} />
                      <Route path="/funny/female" exact component={Gift} />
                      <Route path="/nice/female" exact component={Gift} />
                      <Route path="/suggest" component={Suggest} />
              </Switch>
      </Router>
    );

}

export default App;
