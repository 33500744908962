import React from "react";
import Header from "./Header.jsx"
import Footer from "./Footer.jsx"
import Content from "./Content.jsx"
import {Link} from "react-router-dom";


function Home(){

    return(
        <div>
        <Header />
        <Content
        text1 = "Gift your friend something...."
        perm1 = "1"
        perm2 = "1"
        btn1 = {[<Link class = "nodec" to = "/funny">😂</Link>]}
        btn2 = {[<Link class = "nodec" to = "/nice">🤗</Link>]}
        />
        <Footer />
        </div>
    );

}

export default Home;
