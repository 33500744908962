import React from "react";
import { Blob } from 'react-blob';
import {Link} from "react-router-dom";

function Header(){

    return(
        <div>

        <div class = "normblob">
        <Blob class = "normblob" size="55vh"

             style={{
                 position: 'absolute',
                 top: '-20%',
                 left: '-3.5%',
                 color: 'black',
                 fontSize: '4vh',
                 border: '0.4px solid',
                 fontFamily: 'Roboto'
             }}
       > <Link class = "logo" to = "/" >NiceFunnyGifts </Link>
       </Blob>
       </div>

       <div class = "blobmob">
       <Blob size="30vh"
            style={{
                position: 'absolute',
                top: '-10%',
                left: '-7%',
                color: 'black',
                fontSize: '3vh',
                border: '0.4px solid',
                fontFamily: 'Roboto'
            }}
      > <Link class = "logo" to = "/" >NiceFunnyGifts </Link>
      </Blob>
       </div>

       <Link to = "/suggest" class = "suggest"> suggest? </Link>
       <Link to = "/suggest" class = "mobsugg"> suggest? </Link>
        </div>
    );

}

export default Header;
