import React from "react";
import Header from "./Header.jsx"
import Footer from "./Footer.jsx"
import Content from "./Content.jsx"



function Suggest(){

    return(
        <div>
        <Header />
        <Content
        text1 = "Suggest Away!"

        />
        <Footer />
        </div>
    );

}

export default Suggest;
