import React from "react";
import Header from "./Header.jsx"
import Footer from "./Footer.jsx"
import Content from "./Content.jsx"
import {useLocation} from "react-router-dom";
import {useState} from "react";



const funmale = ["https://amzn.to/385aA22", "https://amzn.to/3yaerpe", "https://amzn.to/3kaSfGE", "https://amzn.to/3jbKLDO", "https://amzn.to/3keFFpX", "https://amzn.to/38b3zwE", "https://amzn.to/388MtQ5", "https://amzn.to/3Bj8Qz9", "https://amzn.to/2ULsuEg", "https://amzn.to/2XUib1N", "https://amzn.to/3gtpl3D", "https://amzn.to/3ydtfn8", "https://amzn.to/3yeAfAj", "https://amzn.to/3kioQKB", "https://amzn.to/3BmmPnR", "https://amzn.to/3sMHBtt", "https://amzn.to/3zdncjS", "https://amzn.to/2WfVEvE"]
const funfemale = ["https://amzn.to/3zdncjS", "https://amzn.to/3sMHBtt", "https://amzn.to/3BmmPnR", "https://amzn.to/3ydtfn8", "https://amzn.to/3kioQKB", "https://amzn.to/3yeAfAj", "https://amzn.to/3Bd265L", "https://amzn.to/3D7WLyp"]
const nicemale = ["https://amzn.to/3j9Ts1J", "https://amzn.to/3zd5IDZ", "https://amzn.to/3D3d9jw", "https://amzn.to/3kfUloO", "https://amzn.to/3B1xvbd", "https://amzn.to/3B7RlBv", "https://amzn.to/3B7WMAx", "https://amzn.to/2Wdcn37", "https://amzn.to/3Docn0S", "https://amzn.to/3B4QXnt", "https://amzn.to/3DeKk3J"]
const nicefemale = ["https://amzn.to/3mpETcl", "https://amzn.to/3B7WMAx", "https://amzn.to/2Wdcn37", "https://amzn.to/3Docn0S", "https://amzn.to/3B4QXnt", "https://amzn.to/3DeKk3J"]
var link = "";
var temp = "";


function Gift(){


  const loc = useLocation();
  const loc1 = loc.pathname;
  const [randno, setGifter] = useState(0);


  function random12(){
      link = linkchanger();
      if (link == null){
        alert("More Gifts will be added soon!");
      }else{
          window.open(link, "_blank");
      }


  }

  function linkchanger(){


      if (loc1 == "/funny/male"){
          setGifter(Math.floor(Math.random() * (funmale.length - 1)));
          temp = funmale[randno];
          delete funmale[randno];
          funmale.splice(randno, 1);
          return (temp);
    } else if (loc1 == "/funny/female") {
          setGifter(Math.floor(Math.random() * (funfemale.length - 1)));
          temp = funfemale[randno];
          delete funfemale[randno];
          funfemale.splice(randno, 1);
          return (temp);
    }else if (loc1 == "/nice/female") {
          setGifter(Math.floor(Math.random() * (nicefemale.length - 1)));
          temp = nicefemale[randno];
          delete nicefemale[randno];
          nicefemale.splice(randno, 1);
          return (temp);

    }else if (loc1 == "/nice/male") {
          setGifter(Math.floor(Math.random() * (nicemale.length - 1)));
          temp = nicemale[randno];
          delete nicemale[randno];
          nicemale.splice(randno, 1);
          return (temp);

    }

  }






    return(
        <div>
        <Header />
        <Content
        text1 = {["it's the thought that counts.       ", <span style={{fontFamily: "Red Hat Display", fontSize:"45px"}}> :D </span>]}
        perm2 = "1"
        btn2 = {[<input type = "button" target="_blank" className = "nodec" onClick = {random12} value = "🎁" />]}
        note1 = "1"
        />
        <Footer />
        </div>
    );

}

export default Gift;
