import React from "react";
import { Blob } from 'react-blob';

function Footer(){

    return(
        <div>

        <div class = "footblob">
        <Blob size="60vh"
             style={{
                 position: 'absolute',
                 bottom: '-30%',
                 right: '-5%',
                 color: 'black',
                 fontSize: '6vh',
                 border: '0.4px solid',

             }}
       ></Blob>
       </div>

       <div class = "footmobblob">
       <Blob size="40vh"
            style={{
                position: 'absolute',
                bottom: '-48%',
                right: '-18%',
                color: 'black',
                border: '0.4px solid',

            }}
      ></Blob>
      </div>

        </div>
    );

}

export default Footer;
